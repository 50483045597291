import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/slapstickFighter/header"
import { Container } from "reactstrap"
import "../../css/press.css"
import "text-image"
import screenshot1 from "../../images/SlapstickFighter/screenshots/Apple iPhone 6S Plus - 1.png"
import screenshot2 from "../../images/SlapstickFighter/screenshots/Apple iPhone 6S Plus - 2.png"
import screenshot3 from "../../images/SlapstickFighter/screenshots/Apple iPhone 6S Plus - 3.png"
import gif1 from "../../images/SlapstickFighter/gifs/8_moves_1262x720_30fps.gif"
import gif2 from "../../images/SlapstickFighter/gifs/ai_freaking_955x550_30fps.gif"
import gif3 from "../../images/SlapstickFighter/gifs/corner_pressure_892x500_30fps.gif"
import gif4 from "../../images/SlapstickFighter/gifs/headbutt_double_KO_fullsize_30fps.gif"
import ReactPlayer from "react-player"


class SlapstickFighterPress extends React.Component {

    constructor(props) {
        super(props)
        // Don't call this.setState() here!
        this.state = {
            emailImageSrc: null,
        }
    }

    componentDidMount() {
        // create new TextImage object with customize style
        var style = {
            font: "Sans-serif",
            align: "left",
            color: "#e4e4e4",
            size: 11,
        }
        
        var textImage = window.TextImage(style)

        // convert text message to image element
        var emailData = textImage.toDataURL(
            atob("Y2hyaXNAY3liZXJuYXRlLmNvbQ==")
        )

        this.setState({
            emailImageSrc: emailData,
        })
    }

    render() {
        return (
            <Layout header={Header} footerClassName="fourzerofour">
                <SEO title="Slapstick Fighter Presskit" />
                <Container fluid className="main-section press">
                    <div className="mx-auto">
                        <h2>Slapstick Fighter Presskit</h2>
                        <p><span className="text-precolon">Publisher: </span>Cybernate</p>
                        <p><span className="text-precolon">Release Date: </span>iOS - 25th July 2019, Android - TBD</p>
                        <p><span className="text-precolon">Platforms: </span>iOS Universal, Android</p>
                        <p><span className="text-precolon">Store Links: </span><a href="https://apps.apple.com/us/app/slapstick-fighter/id1445642543">iOS</a></p>
                        <p><span className="text-precolon">Price: </span>Free</p>
                        <p><span className="text-precolon">Social: </span><a href="https://twitter.com/chris_suffern">@chris_suffern</a></p>
                        <p><span className="text-precolon">Team Members: </span>Chris Suffern</p>
                        <p><span className="text-precolon">Press Contact: </span><img src={this.state.emailImageSrc} alt="" /></p>
                    </div>
                </Container>

                <Container fluid className="main-section press features">
                    <div className="mx-auto">
                        <h2>Features</h2>
                        <p>A physics based fighting game with same-device multiplayer for two players.</p>
                        <p>Two button controls, with a surprising amount of depth, and moves.</p>
                        <p>Single king-hit knockouts make each round tense, quick and brutal.</p>
                        <p>Highlights:</p>
                        <ul>
                            <li>Same-device multiplayer</li>
                            <li>Two button controls</li>
                            <li>A surprising amount of moves for two buttons</li>
                            <li>Physics based combat</li>
                            <li>Easy to play, hard to master</li>
                        </ul>
                    </div>
                </Container>

                <Container fluid className="main-section press">
                    <div className="mx-auto">
                        <h2>Screenshots</h2>
                        <div className="d-flex justify-content-center flex-wrap">
                            <div className="press-image">
                                <img
                                    src={screenshot1}
                                    alt="Slapstick Fighter Screenshot 1"
                                />
                            </div>
                            <div className="press-image">
                                <img
                                    src={screenshot2}
                                    alt="Slapstick Fighter Screenshot 2"
                                />
                            </div>
                            <div className="press-image">
                                <img
                                    src={screenshot3}
                                    alt="Slapstick Fighter Screenshot 3"
                                />
                            </div>
                        </div>
                    </div>
                </Container>

                <Container fluid className="main-section press">
                    <div className="mx-auto">
                        <h2>Gifs</h2>
                        <div className="d-flex justify-content-center flex-wrap">
                            <div className="press-image">
                                <img
                                    src={gif1}
                                    alt="Slapstick Fighter Gif 1"
                                />
                            </div>
                            <div className="press-image">
                                <img
                                    src={gif2}
                                    alt="Slapstick Fighter Gif 2"
                                />
                            </div>
                            <div className="press-image">
                                <img
                                    src={gif3}
                                    alt="Slapstick Fighter Gif 3"
                                />
                            </div>
                            <div className="press-image">
                                <img
                                    src={gif4}
                                    alt="Slapstick Fighter Gif 4"
                                />
                            </div>
                        </div>
                    </div>
                </Container>

                <Container fluid className="main-section press">
                    <div className="mx-auto">
                        <h2>Video</h2>
                        <div className="d-flex justify-content-center flex-wrap">
                            <ReactPlayer
                                url="https://vimeo.com/341957401/c963ac0e27"
                            />
                        </div>
                    </div>
                </Container>

                <Container fluid className="main-section press team">
                    <div className="mx-auto">
                        <h2>The Team</h2>
                        <p>Slapstick Fighter was developed by one man developer Chris Suffern, who works under his Sydney Australia based studio Cybernate. </p>
                        <p>Chris' previous title was the console game <a href="http://www.mutantalienassault.com">Super Mutant Alien Assault</a>, made in Game Maker, with art by the pixel wizard Fabian Jastremski.</p>
                        <p>Slapstick Fighter is Chris' attempt to dip his toe into a new platform (mobile) with a new engine (Unity). </p>
                        <p>It's inspired by mobile games he had fun playing with his son. The same-device multiplayer experience is one he wants to enable others to share with their family and friends.</p>
                    </div>
                </Container>

                

            </Layout>
        )
    }
}

export default SlapstickFighterPress